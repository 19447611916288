// Buttons

button {
  padding: 1rem 1.5rem;
  border: none;
  border-radius: 5rem;
  margin: 1rem 0;
  width: fit-content;
  background-color: $accent-color;
  transition: $main-transition;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.6rem;
}

button:hover {
  background-color: $accent-color-2;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
}
