// Variables

//Fonts
$heading-font: 'Prata',
  serif;
$body-font: 'Nimbus Sans L',
  sans-serif;

//Colours

$primary-color: #0F00FF;
$secondary-color: #09009B;
$accent-color: #FFA400;
$accent-color-2: #D98C00;
$neutral-color: #767498;
$neutral-color-2: #DDDBFF;

//Transitions
$main-transition: 0.3s ease-in-out;