// Grid

.services-grid,
.projects-grid {
  display: grid;
  grid-template-columns: 100%;
  gap: 1.5rem;
  justify-content: center;
}

/* Tablet & Laptop View */
@media (min-width: 768px) {

  .services-grid,
  .projects-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1.5rem;
    justify-content: center;
  }
}

/* Desktop View */
@media (min-width: 1200px) {

  .services-grid,
  .projects-grid {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 1.5rem;
    justify-content: center;
  }
}