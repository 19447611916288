// Typography

// Fonts

@font-face {
  font-family: 'Nimbus Sans L';
  src: url('../../assets/fonts/NimbusSanL-RegIta.eot');
  src: url('../../assets/fonts/NimbusSanL-RegIta.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/NimbusSanL-RegIta.woff2') format('woff2'),
    url('../../assets/fonts/NimbusSanL-RegIta.woff') format('woff'),
    url('../../assets/fonts/NimbusSanL-RegIta.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: '../../assets/fonts/Nimbus Sans L';
  src: url('../../assets/fonts/NimbusSanL-Reg.eot');
  src: url('../../assets/fonts/NimbusSanL-Reg.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/NimbusSanL-Reg.woff2') format('woff2'),
    url('../../assets/fonts/NimbusSanL-Reg.woff') format('woff'),
    url('../../assets/fonts/NimbusSanL-Reg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nimbus Sans L';
  src: url('../../assets/fonts/NimbusSanL-Bol.eot');
  src: url('../../assets/fonts/NimbusSanL-Bol.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/NimbusSanL-Bol.woff2') format('woff2'),
    url('../../assets/fonts/NimbusSanL-Bol.woff') format('woff'),
    url('../../assets/fonts/NimbusSanL-Bol.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nimbus Sans L';
  src: url('../../assets/fonts/NimbusSanL-BolIta.eot');
  src: url('../../assets/fonts/NimbusSanL-BolIta.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/NimbusSanL-BolIta.woff2') format('woff2'),
    url('../../assets/fonts/NimbusSanL-BolIta.woff') format('woff'),
    url('../../assets/fonts/NimbusSanL-BolIta.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Prata';
  src: url('../../assets/fonts/Prata-Regular.eot');
  src: url('../../assets/fonts/Prata-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Prata-Regular.woff2') format('woff2'),
    url('../../assets/fonts/Prata-Regular.woff') format('woff'),
    url('../../assets/fonts/Prata-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lobster';
  src: url('../../assets/fonts/Lobster14.eot');
  src: url('../../assets/fonts/Lobster14.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/Lobster14.woff2') format('woff2'),
    url('../../assets/fonts/Lobster14.woff') format('woff'),
    url('../../assets/fonts/Lobster14.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Typography

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  line-height: 1.6;
  font-family: $body-font;
  cursor: default;
}

h1,
h2,
h3,
h4 {
  font-family: $heading-font;
  margin: 1.5rem 0;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 2rem;
}

p,
li,
span,
a,
label,
input,
textarea {
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.6rem;
  color: #333333;
}

li {
  list-style-position: inside;
}

/* Links */

a {
  text-decoration: none;
  color: $primary-color;
  transition: $main-transition;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
  color: $secondary-color;
}