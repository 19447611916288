// Page Layout

.page-container {
  width: 90%;
  margin: auto;
}

/* Tablet & Laptop View */
@media (min-width: 768px) {
  .page-container {
    width: 80%;
  }
}

/* Desktop View */
@media (min-width: 1200px) {
  .page-container {
    max-width: 1200px;
  }
}

article {
  margin: 2.5rem 0;
}