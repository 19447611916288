// Nav Bar

nav {
  width: 100%;
}

.nav-container {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}

#logo {
  width: 15rem;
  height: auto;
}

.nav-links {
  width: 100%;
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-transform: uppercase;
  }

  li {
    margin: 0 1.5rem;
    cursor: pointer;
  }

  a {
    color: #333333;
  }

  a:hover {
    color: $accent-color-2;
  }
}

// Hamburger Menu

.hamburger {
  display: grid;
  border: none;
  background: transparent;
  cursor: pointer;
  z-index: 100;
  color: black;
  padding: 0;
  position: relative;

  svg {
    font-size: 3rem;
  }
}

.hamburger:hover {
  background: transparent;
}

.menu-icon,
.close-icon {
  grid-row: 1;
  grid-column: 1;
}

.hamburger-menu {
  position: fixed;
  transform: translateX(100%);
  transition: transform 0.3s;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: white;
  padding-top: 4rem;
  text-align: center;

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    padding-top: 5rem;
  }

  li {
    padding: 1rem;
  }

  a {
    color: #333333;
  }

  a:hover {
    color: $accent-color-2;
  }
}

.showMenu {
  transform: translateX(0);
}

.hideOverflow {
  height: 100vh;
  overflow-y: hidden;
}

.displayNone {
  visibility: hidden;
  display: none;
}

/* Tablet & Laptop View */
@media (min-width: 768px) {
  .nav-container {
    width: 80%;
  }
}

/* Desktop View */
@media (min-width: 1080px) {
  .nav-links {
    display: flex;
  }

  .nav-container {
    justify-content: flex-start;
  }

  .hamburger {
    display: none;
  }

}

/* Desktop View */
@media (min-width: 1200px) {
  .nav-container {
    max-width: 1200px;
  }
}