/* Forms */

form {
  display: flex;
  flex-direction: column;
  width: 30rem;
  margin: 0 auto;
  padding-top: 3rem;

  span {
    color: red;
  }
}

input,
textarea {
  border: 1px solid lightgrey;
  padding: 0.5rem 1rem;
  max-width: 100%;
  margin: 0.5rem 0;
}

label {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.form-button-container {
  margin: 0 auto;
}

/* Tablet & Laptop View */
@media (min-width: 768px) {
  form {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 40rem;
  }
}

/* Desktop View */
@media (min-width: 1200px) {
  form {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
}