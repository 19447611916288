// Homepage

// Services
.services-section {
  text-align: center;
}

.service-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem;
  padding: 1rem;
  border-radius: 2rem;
  transition: $main-transition;

  img {
    width: 15rem;
    height: auto;
  }
}

.service-tile:hover {
  box-shadow: 0 1rem 1rem $primary-color;
  transform: translateY(-1rem);
}

// Choose Us
.choose-us-section {
  text-align: center;

  button {
    margin: 1.5rem 0;
  }
}

.choose-us-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    display: none;
  }
}

//Portfolio
.projects-section {
  text-align: center;
}

.portfolio-tile {
  img {
    width: 25rem;
    height: auto;
  }
}

//Testimonials
.testimonial-section {
  text-align: center;
}

.testimonial-text {
  font-style: italic;
}

.testimonial-name {
  font-weight: bold;
}

.testimonial-tile-container {
  display: flex;
  justify-content: center;
}

.testimonial-tile {
  width: 80%;
}

//Contact Us
.contact-us-section {

  h2,
  p {
    text-align: center;
  }

  .error-message {
    text-align: left;
    color: red;
  }
}

.contact-form-img {
  display: flex;
  flex-direction: column;
}

.contact-img {
  text-align: center;

  img {
    width: 30rem;
    height: auto;
  }
}

/* Tablet & Laptop View */
@media (min-width: 768px) {

  .contact-img {
    text-align: center;

    img {
      width: 50rem;
      height: auto;
    }
  }
}

/* Desktop View */
@media (min-width: 1200px) {

  .choose-us-info {
    img {
      display: block;
      width: 20rem;
      height: auto;
    }
  }

  .contact-form-img {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .contact-img {
    text-align: center;

    img {
      width: 50rem;
      height: auto;
    }
  }
}