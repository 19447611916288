// Footer

footer {
  background-image: url(../../assets/images/space-bg.PNG), linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  padding: 3rem 0;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: white;
    text-align: center;
  }

  div {
    margin: 1.5rem 0;
  }
}